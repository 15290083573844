import { translation, type TranslatedComponent } from '@hh.ru/front-static-app';
import { Avatar, Cell, CellText, CheckableCard, type AvatarProps } from '@hh.ru/magritte-ui';

import type { AccountType } from 'src/models/accountLogin';

const AvatarPlaceholderMap: Record<AccountType, AvatarProps['placeholder']> = {
    APPLICANT: 'undefined',
    EMPLOYER: 'city',
} as const;

const TrlKeys = {
    APPLICANT: {
        title: 'applicant.login.step.accountTypeSelection.option.applicant.title',
        subtitle: 'applicant.login.step.accountTypeSelection.option.applicant.subtitle',
    },
    EMPLOYER: {
        title: 'applicant.login.step.accountTypeSelection.option.employer.title',
        subtitle: 'applicant.login.step.accountTypeSelection.option.employer.subtitle',
    },
};

interface AccountTypeCardProps {
    accountType: AccountType;
    isChecked?: boolean;
    onChange?: (value: AccountType) => void;
}

const AccountTypeCard: TranslatedComponent<AccountTypeCardProps> = ({ trls, accountType, isChecked, onChange }) => {
    const handleChange = (): void => {
        if (onChange) {
            onChange(accountType);
        }
    };

    return (
        <CheckableCard
            type="radio"
            name="account-type"
            padding={24}
            borderRadius={24}
            checked={isChecked}
            onChange={handleChange}
        >
            <Cell
                avatar={
                    <Avatar
                        aria-label={accountType}
                        size={48}
                        mode="placeholder"
                        placeholder={AvatarPlaceholderMap[accountType]}
                        shapeCircle={accountType === 'EMPLOYER'}
                    />
                }
            >
                <CellText>{trls[TrlKeys[accountType].title]}</CellText>
                <CellText type="subtitle">{trls[TrlKeys[accountType].subtitle]}</CellText>
            </Cell>
        </CheckableCard>
    );
};

export default translation(AccountTypeCard);
