import type { ReactNode } from 'react';
import { Field } from 'react-final-form';

import { formatToReactComponent, type TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { Input } from '@hh.ru/magritte-ui';

import {
    type ApplicantLoginFormValues,
    type Validator,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import {
    CODE_FIELD,
    mustBeNumber,
    NOT_A_NUMBER_ERROR,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';
import { LoginByCodeErrorKey } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useLogin/types';
import TimeoutCounter from 'src/components/OTP/TimeoutCounter';
import useAutofocus from 'src/hooks/useAutofocus';
import type { Verification } from 'src/models/applicant/auth';
import type { OtpType } from 'src/models/otp';

const TrlKeys = {
    valueMissing: 'form.error.valueMissing',
    placeholder: 'account.connect.merge_by_code.codePlaceholder',
    error: {
        [NOT_A_NUMBER_ERROR]: 'login.form.otp.passwordLike',
        [LoginByCodeErrorKey.CodeNotFound]: 'account.connect.merge_by_code.email.error.CODE_NOT_FOUND',
        [LoginByCodeErrorKey.ConfirmCodeBlocked]: 'account.connect.merge_by_code.email.error.CONFIRM_CODE_BLOCKED',
        [LoginByCodeErrorKey.ConfirmCodeBlockedPhone]:
            'account.connect.merge_by_code.email.error.CONFIRM_CODE_BLOCKED.phone',
        [LoginByCodeErrorKey.WrongCode]: 'account.connect.merge_by_code.email.error.WRONG_CODE',
        [LoginByCodeErrorKey.CodeExpired]: 'account.connect.merge_by_code.email.error.CODE_EXPIRED',
        [LoginByCodeErrorKey.AccountBlocked]: 'error.employer.discarded',
        [LoginByCodeErrorKey.PasswordLike]: 'login.form.otp.passwordLike',
        [LoginByCodeErrorKey.Default]: 'account.connect.merge_by_code.email.error.unknown',
    },
};

interface OtpCodeFieldProps {
    otpType: OtpType;
    codeLength: number;
    verification?: Verification | null;
    onSubmit: () => Promise<void>;
}

const OtpCodeField: TranslatedComponent<OtpCodeFieldProps> = ({
    trls,
    otpType,
    codeLength,
    verification,
    onSubmit,
}) => {
    const autofocusRef = useAutofocus();

    const getErrorMessage = (error?: keyof typeof TrlKeys.error): ReactNode => {
        if (!error) {
            return null;
        }

        if (error === LoginByCodeErrorKey.ConfirmCodeBlocked) {
            if (!verification) {
                return trls[TrlKeys.error[LoginByCodeErrorKey.Default]];
            }

            if (otpType === 'phone') {
                error = LoginByCodeErrorKey.ConfirmCodeBlockedPhone;
            }

            const { nextConfirmTime } = verification;

            return formatToReactComponent(trls[TrlKeys.error[error]], {
                '{0}': <TimeoutCounter till={nextConfirmTime['@timestamp'] || nextConfirmTime.timestamp} />,
            });
        }

        return trls[TrlKeys.error[error]];
    };

    const validate: Validator<ApplicantLoginFormValues['code']> = async (value, _, meta) => {
        const wasModified = meta?.modifiedSinceLastSubmit;

        if (wasModified && value && value.length === codeLength) {
            const formValidationError = mustBeNumber(value);

            if (autofocusRef.current) {
                autofocusRef.current.blur();
            }

            if (formValidationError) {
                return formValidationError;
            }

            await onSubmit();
        }

        return undefined;
    };

    return (
        <Field<ApplicantLoginFormValues['code']>
            name={CODE_FIELD}
            type="text"
            validate={validate}
            render={({ input, meta }) => {
                const submitError = meta.dirtySinceLastSubmit ? null : (meta.submitError as string);
                const error = (meta.error || submitError) as keyof typeof TrlKeys.error;
                const errorMessage = getErrorMessage(error);

                return (
                    <Input
                        {...input}
                        ref={autofocusRef}
                        value={input.value ?? ''}
                        placeholder={trls[TrlKeys.placeholder]}
                        maxLength={codeLength}
                        size="large"
                        invalid={meta.touched && !!errorMessage}
                        errorMessage={errorMessage}
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoFocus
                        spellCheck={false}
                        clearableOnFocus={false}
                    />
                );
            }}
        />
    );
};

export default translation(OtpCodeField);
