import type { AxiosResponse } from 'axios';

import type { PostFormData } from 'Modules/prepareFormData';
import type { FetchResult } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/types';
import type { AccountType } from 'src/models/accountLogin';
import type { Verification } from 'src/models/applicant/auth';
import { OtpOperationType } from 'src/models/otp';

interface LoginByCodeBody {
    username: string;
    code: string;
    accountType: AccountType;
    operationType: OtpOperationType;
    backurl: string;
    remember?: boolean;
    isApplicantSignup?: boolean;
}

type LoginByCodeResponse = AxiosResponse<FetcherPostApi['/account/login/by_code']['response']>;
type LoginByCodeData = LoginByCodeResponse['data'];

export enum LoginByCodeErrorKey {
    CodeNotFound = 'CODE_NOT_FOUND',
    ConfirmCodeBlocked = 'CONFIRM_CODE_BLOCKED',
    ConfirmCodeBlockedPhone = 'CONFIRM_CODE_BLOCKED_PHONE',
    WrongCode = 'WRONG_CODE',
    CodeExpired = 'CODE_EXPIRED',
    AccountNotFound = 'ACCOUNT_NOT_FOUND',
    AccountBlocked = 'ACCOUNT_BLOCKED',
    PasswordLike = 'PASSWORD_LIKE',
    EmployerNotAllowed = 'EMPLOYER_NOT_ALLOWED',
    Default = 'DEFAULT',
}

interface LoginByCodeError {
    key: LoginByCodeErrorKey;
    description: string | null;
    location: string | null;
}

export interface PostLoginByCodeError {
    verification?: Verification;
    error?: LoginByCodeError;
    employerLoginURL?: string;
    loginURL?: string;
    isMultiAccountCreation?: boolean;
}

export interface PostLoginByCodePayload extends PostFormData, LoginByCodeBody {}
export type PostLoginByCodeResult = FetchResult<
    LoginByCodeData,
    { key: LoginByCodeErrorKey; data?: Omit<PostLoginByCodeError, 'error'> }
>;

export interface LoginWithCodeParams extends Pick<LoginByCodeBody, 'code' | 'accountType' | 'isApplicantSignup'> {
    login: string;
    backUrl?: string;
}
export type LoginWithCodeReturn = PostLoginByCodeResult;

interface LoginByPasswordBody {
    username: string;
    password: string;
    failUrl?: string;
    remember?: boolean;
    isBot?: boolean;
}

type LoginByPasswordResponse = AxiosResponse<FetcherPostApi['/account/login']['response']>;
type LoginByPasswordData = LoginByPasswordResponse['data'];

export enum LoginByPasswordErrorKey {
    BadCredentials = 'mismatch',
    Unknown = 'UNKNOWN',
}

export interface PostLoginByPasswordPayload extends PostFormData, LoginByPasswordBody {}
export type PostLoginByPasswordResult = FetchResult<LoginByPasswordData, LoginByPasswordErrorKey>;

export interface LoginWithPasswordParams extends Pick<LoginByPasswordBody, 'password'> {
    login: string;
    failUrl?: string;
}
export type LoginWithPasswordReturn = PostLoginByPasswordResult;
