import { useSelector } from '@hh.ru/front-static-app';

import useLogin from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useLogin';
import { LoginByCodeErrorKey } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useLogin/types';
import useOtp from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useOtp';
import useSignUp from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useSignUp';
import type { ApplicantLoginStep } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/types';
import type { Verification } from 'src/models/applicant/auth';
import { OtpOperationType } from 'src/models/otp';

import {
    AuthCredentialType,
    type ApplicantLoginFormValues,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import {
    defaultValues,
    OtpTypeMap,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';

interface UseApplicantLoginFormParams {
    step: ApplicantLoginStep | null;
    isSignupPage: boolean;
    onVerificationChange: (value: Verification) => void;
    onCodeLengthChange: (value: number) => void;
}

interface UseApplicantLoginForm {
    initialValues: ApplicantLoginFormValues;
    onSubmit: (value: ApplicantLoginFormValues) => Promise<Partial<ApplicantLoginFormValues> | undefined>;
}

const useApplicantLoginForm = ({
    step,
    isSignupPage,
    onVerificationChange,
    onCodeLengthChange,
}: UseApplicantLoginFormParams): UseApplicantLoginForm => {
    const authParams = useSelector((state) => state.authUrl);

    const { sendOtpCode } = useOtp();
    const { loginWithCode, loginWithPassword } = useLogin();
    const { signUp } = useSignUp();

    const initialValues: UseApplicantLoginForm['initialValues'] = { ...defaultValues };

    const previousUsername = authParams?.['login-field-value-entered'] ?? authParams?.['login-field-value'];

    if (previousUsername) {
        initialValues.username = previousUsername;

        if (previousUsername.includes('@')) {
            initialValues.credentialType = AuthCredentialType.Email;
        }
    }

    const onSubmit: UseApplicantLoginForm['onSubmit'] = async (values) => {
        const { credentialType, username: login, password, code = '', firstName, lastName, advAgreement } = values;

        if (step === 'enter-credentials') {
            const otpType = OtpTypeMap[credentialType];

            const result = await sendOtpCode({ login, otpType, operationType: OtpOperationType.ApplicantOtpAuth });

            if (!result.success) {
                return { username: result.error };
            }

            onCodeLengthChange(result.data.codeLength);
        }

        if (step === 'enter-otp-code') {
            const result = await loginWithCode({ login, code, accountType: 'APPLICANT' });

            if (!result.success) {
                const { key, data } = result.error;

                if (data?.verification) {
                    onVerificationChange(data.verification);
                }

                if (key === LoginByCodeErrorKey.EmployerNotAllowed && data?.employerLoginURL) {
                    window.location.assign(data.employerLoginURL);
                }

                return { code: key };
            }

            window.location.assign(result.data.backurl ?? '/');
        }

        if (step === 'enter-password') {
            const result = await loginWithPassword({ login, password });

            if (!result.success) {
                return { password: result.error };
            }

            window.location.assign(result.data.redirectUrl ?? '/');
        }

        if (step === 'enter-name') {
            const result = await signUp({
                firstName,
                lastName,
                login,
                password,
                code,
                isSignupPage,
                hasAdConsent: advAgreement,
            });

            if (!result.success) {
                return { firstName: result.error.firstName, lastName: result.error.lastName };
            }

            window.location.assign(result.data.backurl);
        }

        return undefined;
    };

    return { initialValues, onSubmit };
};

export default useApplicantLoginForm;
