import { useEffect } from 'react';
import { useForm } from 'react-final-form';

import { format, formatToReactComponent, type TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { VSpacingContainer, Title, Text, Button } from '@hh.ru/magritte-ui';

import {
    ApplicantLoginFormValues,
    AuthCredentialType,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import {
    CODE_FIELD,
    OtpTypeMap,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';
import { useApplicantLoginContext } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/context';
import { LoginByCodeErrorKey } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useLogin/types';
import useOtp from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useOtp';

import OtpCodeField from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterOtpCodeStep/OtpCodeField';
import OtpCodeSender from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterOtpCodeStep/OtpCodeSender';

const TrlKeys = {
    title: {
        common: 'applicant.login.step.enterOtpCode.title',
        [AuthCredentialType.Phone]: 'applicant.login.step.enterOtpCode.title.phone',
        [AuthCredentialType.Email]: 'applicant.login.step.enterOtpCode.title.email',
    },
    subtitle: {
        common: 'applicant.login.step.enterOtpCode.subtitle',
        emailTip: 'applicant.login.step.enterOtpCode.subtitle.email.tip',
    },
    action: {
        [AuthCredentialType.Phone]: 'applicant.login.step.enterOtpCode.action.changePhone',
        [AuthCredentialType.Email]: 'applicant.login.step.enterOtpCode.action.changeEmail',
    },
};

const EnterOtpCodeStep: TranslatedComponent = ({ trls }) => {
    const { verification, codeLength, onNextStep, onStepReplace } = useApplicantLoginContext();
    const { mutators, getState, submit } = useForm<ApplicantLoginFormValues>();
    const { credentialType, username } = getState().values;
    const { sendOtpCode } = useOtp();

    const otpType = OtpTypeMap[credentialType];

    useEffect(() => {
        mutators.removeValue(CODE_FIELD);
    }, [mutators]);

    const handleSubmit = async (): Promise<void> => {
        const result = await submit();

        if (result?.code === LoginByCodeErrorKey.AccountNotFound) {
            onNextStep('enter-name');
        }
    };

    const handleSendCode = async (): Promise<number | undefined> => {
        const result = await sendOtpCode({ otpType, login: username });

        if (result.success) {
            return result.data.otp.secondsUntilNextSend;
        }

        return undefined;
    };

    const handleChangeCredentialButtonClick = (): void => {
        onStepReplace('enter-credentials');
    };

    return (
        <VSpacingContainer default={24}>
            <Title
                Element="h2"
                size="medium"
                alignment="center"
                description={
                    <>
                        <Text Element="p" typography="paragraph-2-regular" style="secondary">
                            {formatToReactComponent(trls[TrlKeys.subtitle.common], {
                                '{0}': <Text Element="span">{username}</Text>,
                            })}
                        </Text>
                        {credentialType === AuthCredentialType.Email && (
                            <Text Element="p" typography="paragraph-2-regular" style="secondary">
                                {trls[TrlKeys.subtitle.emailTip]}
                            </Text>
                        )}
                    </>
                }
            >
                {format(trls[TrlKeys.title.common], {
                    '{0}': trls[TrlKeys.title[credentialType]],
                })}
            </Title>
            <OtpCodeField
                otpType={otpType}
                codeLength={codeLength}
                verification={verification}
                onSubmit={handleSubmit}
            />
            <VSpacingContainer default={12}>
                <OtpCodeSender onSend={handleSendCode} />
                <Button size="large" mode="secondary" style="accent" onClick={handleChangeCredentialButtonClick}>
                    {trls[TrlKeys.action[credentialType]]}
                </Button>
            </VSpacingContainer>
        </VSpacingContainer>
    );
};

export default translation(EnterOtpCodeStep);
