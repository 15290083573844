import { useState } from 'react';
import { useForm } from 'react-final-form';

import type { TranslatedComponent } from '@hh.ru/front-static-app';
import { translation, useSelector } from '@hh.ru/front-static-app';
import { VSpacingContainer, Button, Title } from '@hh.ru/magritte-ui';

import {
    AuthCredentialType,
    type ApplicantLoginFormValues,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import { useApplicantLoginContext } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/context';
import { useDisableSocialNetworkAuthExp } from 'src/hooks/useDisableSocialNetworkAuthExp';
import useIsIframeView from 'src/hooks/useIsIframeView';

import CredentialField from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/CredentialField';
import CredentialTypeSwitch from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/CredentialTypeSwitch';
import LegalInfo from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/LegalInfo';
import SocialLoginButton from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/SocialLoginButton';
import { FlowKey } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/EnterCredentialsStep/types';

const TrlKeys = {
    [FlowKey.SignIn]: {
        title: 'applicant.login.step.enterCredentials.title.signIn',
        action: {
            next: 'applicant.login.step.enterCredentials.action.next',
            loginWithPassword: 'applicant.login.step.enterCredentials.action.authWithPassword',
        },
    },
    [FlowKey.SignUp]: {
        title: 'applicant.login.step.enterCredentials.title.signUp',
        action: {
            next: 'applicant.login.step.enterCredentials.action.next',
        },
    },
};

const EnterCredentialsStep: TranslatedComponent = ({ trls }) => {
    const { socialNetworks } = useSelector(({ loginForm }) => loginForm);
    const { flow, onNextStep } = useApplicantLoginContext();

    const { getState, getFieldState, blur, submit } = useForm<ApplicantLoginFormValues>();
    const { credentialType: initialCredentialType } = getState().values;

    const [credentialType, setCredentialType] = useState(initialCredentialType);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const isIframeView = useIsIframeView();
    const isDisableSocialNetworkAuthExp = useDisableSocialNetworkAuthExp();

    const flowKey = flow === 'sign-in' ? FlowKey.SignIn : FlowKey.SignUp;
    const shouldShowSocialNetworks = !!socialNetworks?.length && !isIframeView && !isDisableSocialNetworkAuthExp;

    const isUsernameFieldValid = (): boolean => getFieldState('username')?.valid ?? false;

    const handleCredentialTypeChange = (value: AuthCredentialType): void => {
        setCredentialType(value);
    };

    const handleNextButtonClick = async (): Promise<void> => {
        setIsSubmitting(true);
        await submit();
        setIsSubmitting(false);

        if (isUsernameFieldValid()) {
            onNextStep('enter-otp-code');
        }
    };

    const handleLoginWithPasswordButtonClick = (): void => {
        // Выполняем клиентскую валидацию перед переходом к форме пароля
        blur('username');

        if (isUsernameFieldValid()) {
            onNextStep('enter-password');
        }
    };

    const handleSocialLoginButtonClick = (): void => {
        onNextStep('social-login');
    };

    return (
        <VSpacingContainer default={24}>
            <Title Element="h2" size="medium" alignment="center">
                {trls[TrlKeys[flowKey].title]}
            </Title>
            <CredentialTypeSwitch onChange={handleCredentialTypeChange} />
            {/* Меняем key для перемонтирования компонента при смене credentialType
                для очистки поля ввода от ошибки и корректного автофокуса */}
            <CredentialField key={credentialType} credentialType={credentialType} />
            <VSpacingContainer default={12}>
                <Button
                    mode="primary"
                    size="large"
                    style="accent"
                    disabled={isSubmitting}
                    onClick={handleNextButtonClick}
                >
                    {trls[TrlKeys[flowKey].action.next]}
                </Button>
                {flow === 'sign-in' && (
                    <Button
                        mode="secondary"
                        size="large"
                        style="accent"
                        disabled={isSubmitting}
                        onClick={handleLoginWithPasswordButtonClick}
                    >
                        {trls[TrlKeys[FlowKey.SignIn].action.loginWithPassword]}
                    </Button>
                )}
            </VSpacingContainer>
            {shouldShowSocialNetworks && (
                <SocialLoginButton socialNetworks={socialNetworks} onClick={handleSocialLoginButtonClick} />
            )}
            <LegalInfo />
        </VSpacingContainer>
    );
};

export default translation(EnterCredentialsStep);
