import type { OtpType } from 'src/models/otp';

import {
    AuthCredentialType,
    type ApplicantLoginFormValues,
    type ValidationResult,
    type Validator,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';

export const OtpTypeMap: Record<AuthCredentialType, OtpType> = {
    [AuthCredentialType.Email]: 'email',
    [AuthCredentialType.Phone]: 'phone',
};

export const CREDENTIAL_TYPE_FIELD: Extract<keyof ApplicantLoginFormValues, 'credentialType'> = 'credentialType';
export const USERNAME_FIELD: Extract<keyof ApplicantLoginFormValues, 'username'> = 'username';
export const PASSWORD_FIELD: Extract<keyof ApplicantLoginFormValues, 'password'> = 'password';
export const CODE_FIELD: Extract<keyof ApplicantLoginFormValues, 'code'> = 'code';
export const CODE_2FA_FIELD: Extract<keyof ApplicantLoginFormValues, 'code2fa'> = 'code2fa';
export const FIRST_NAME_FIELD: Extract<keyof ApplicantLoginFormValues, 'firstName'> = 'firstName';
export const LAST_NAME_FIELD: Extract<keyof ApplicantLoginFormValues, 'lastName'> = 'lastName';
export const ADV_AGREEMENT_FIELD: Extract<keyof ApplicantLoginFormValues, 'advAgreement'> = 'advAgreement';

export const defaultValues: ApplicantLoginFormValues = {
    [CREDENTIAL_TYPE_FIELD]: AuthCredentialType.Phone,
    [USERNAME_FIELD]: '',
    [PASSWORD_FIELD]: '',
    [CODE_FIELD]: '',
    [CODE_2FA_FIELD]: '',
    [FIRST_NAME_FIELD]: '',
    [LAST_NAME_FIELD]: '',
    [ADV_AGREEMENT_FIELD]: false,
};

export const MISSING_VALUE_ERROR = 'MISSING_VALUE';
export const NOT_A_NUMBER_ERROR = 'NOT_A_NUMBER';
export const INVALID_EMAIL_ERROR = 'INVALID_EMAIL';
export const INVALID_PHONE_ERROR = 'INVALID_PHONE';

export const composeValidators =
    (...validators: Validator[]): Validator =>
    (value, allValues, meta) =>
        validators.reduce<ValidationResult | Promise<ValidationResult>>(
            (error, validator) => error || validator(value, allValues, meta),
            undefined
        );

export const required: Validator = (value) => (value?.trim() ? undefined : MISSING_VALUE_ERROR);

export const mustBeNumber: Validator = (value) => (isNaN(Number(value)) ? NOT_A_NUMBER_ERROR : undefined);

export const mustBeEmail: Validator = (value) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return value && emailRegex.test(value) ? undefined : INVALID_EMAIL_ERROR;
};

export const mustBePhone: Validator = (value) => {
    const phoneRegex = /(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})/g;
    return value && phoneRegex.test(value) ? undefined : INVALID_PHONE_ERROR;
};
