import { useEffect, useRef } from 'react';
import { useField } from 'react-final-form';

import Analytics from '@hh.ru/analytics-js';
import { VSpacing, Title } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import Captcha from 'src/components/AccountLogin/Form/components/Captcha';
import Header from 'src/components/AccountLogin/components/Title/Header';
import { useLoginContext } from 'src/components/AccountLogin/hooks/useLoginContext';
import Button from 'src/components/MagritteRedesignComponents/Button';
import Text from 'src/components/MagritteRedesignComponents/Text';
import CodeInput from 'src/components/OTP/CodeInput';
import CodeSender from 'src/components/OTP/CodeSender';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { OtpOperationType } from 'src/models/otp';

const TrlKeys = {
    submit: 'login.action.signup.submit',
    header: 'login.form.otp.header',
    employerHeader: 'employer.login.otp.header',
    subHeader: 'login.form.otp.subHeader',
    noCode: 'login.form.otp.noCode',
    employerOtpSubHeader: 'employer.login.otp.subHeader',
};

interface VerificationCodeStepProps {
    onNoAccessToEmail: () => void;
}

const VerificationCodeStep: TranslatedComponent<VerificationCodeStepProps> = ({ trls, onNoAccessToEmail }) => {
    const isMagritte = useMagritte();
    const { isSubmitting, verificationStatus, actionName, isEmployerForm, isMultiAccount } = useLoginContext();
    const accountType = verificationStatus?.accountType || 'EMPLOYER';
    const username = useField('username').input.value as string;
    const contentRef = useRef<HTMLDivElement>(null);
    const operationType =
        accountType === 'EMPLOYER' ? OtpOperationType.EmployerOtpAuth : OtpOperationType.ApplicantOtpAuth;

    useEffect(() => {
        if (contentRef.current) {
            Analytics.sendHHEventElementShown(contentRef.current, {
                name: `auth_${isEmployerForm ? 'employer' : 'applicant'}_send_code`,
                actionName,
                accountType,
                isMultiAccount,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNoAccessToEmail = () => {
        Analytics.sendHHEventButtonClick('one_time_password_not_get');
        onNoAccessToEmail();
    };

    const titleText = isEmployerForm
        ? trls[TrlKeys.employerHeader]
        : format(trls[TrlKeys.header], {
              '{0}': username,
          });

    const subHeaderText = isEmployerForm
        ? format(trls[TrlKeys.employerOtpSubHeader], {
              '{email}': username,
          })
        : trls[TrlKeys.subHeader];

    const title = isMagritte ? (
        <Title Element="h1" size="small" description={subHeaderText}>
            {titleText}
        </Title>
    ) : (
        <Header subheader={subHeaderText}>{titleText}</Header>
    );

    return (
        <div ref={contentRef}>
            {title}
            <VSpacing default={isMagritte ? 24 : 12} />
            <CodeInput dataQa="account-login-code-input" verificationStatus={verificationStatus} />
            <Captcha />
            <VSpacing default={8} />
            <Text style="secondary">
                <CodeSender
                    url="/shards/otp_generate"
                    login={username}
                    otpType="email"
                    operationType={operationType}
                    disableCaptcha
                />
            </Text>
            <VSpacing default={24} />
            <Button
                size="large"
                style="accent"
                mode="primary"
                data-qa="account-login-submit"
                stretched
                type="submit"
                disabled={isSubmitting}
            >
                {trls[TrlKeys.submit]}
            </Button>
            <VSpacing default={12} />
            <Button
                size="large"
                style="accent"
                mode="tertiary"
                data-qa="account-login-no-access-email"
                stretched
                onClick={handleNoAccessToEmail}
            >
                {trls[TrlKeys.noCode]}
            </Button>
        </div>
    );
};

export default translation(VerificationCodeStep);
