import type { FieldState, FieldValidator } from 'final-form';

import type { LoginFormValues } from 'src/components/AccountLogin/types';

export enum AuthCredentialType {
    Phone = 'PHONE',
    Email = 'EMAIL',
}

export interface ApplicantLoginFormValues extends LoginFormValues {
    credentialType: AuthCredentialType;
    firstName: string;
    lastName: string;
    advAgreement: boolean;
}

export type ValidationResult = string | undefined;

export interface Validator<Value = string, Values = object> extends FieldValidator<Value> {
    (value: Value, allValues?: Values, meta?: FieldState<Value>): ValidationResult | Promise<ValidationResult>;
}
