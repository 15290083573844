import { type ComponentProps, type ReactNode } from 'react';
import { Field } from 'react-final-form';

import { type TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { Input } from '@hh.ru/magritte-ui';

import {
    AuthCredentialType,
    type ApplicantLoginFormValues,
    type Validator,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/types';
import {
    composeValidators,
    INVALID_EMAIL_ERROR,
    INVALID_PHONE_ERROR,
    MISSING_VALUE_ERROR,
    mustBeEmail,
    mustBePhone,
    required,
    USERNAME_FIELD,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/utils';
import { GenerateOtpResponseKey } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useOtp/types';
import PhoneVerificationError from 'src/components/ApplicantResumes/PhoneVerification/components/PhoneVerificationError';
import useAutofocus from 'src/hooks/useAutofocus';

const UsernameValidatorMap: Record<AuthCredentialType, Validator> = {
    [AuthCredentialType.Email]: mustBeEmail,
    [AuthCredentialType.Phone]: mustBePhone,
};

const ErrorMessageMap: Record<AuthCredentialType, typeof INVALID_EMAIL_ERROR | typeof INVALID_PHONE_ERROR> = {
    [AuthCredentialType.Email]: INVALID_EMAIL_ERROR,
    [AuthCredentialType.Phone]: INVALID_PHONE_ERROR,
};

const TrlKeys = {
    [MISSING_VALUE_ERROR]: 'form.error.valueMissing',
    [INVALID_PHONE_ERROR]: 'applicant.login.form.error.wrongPhone',
    [INVALID_EMAIL_ERROR]: 'applicant.login.form.error.wrongEmail',
    [GenerateOtpResponseKey.BadLogin]: 'form.error.patternMismatch',
    [GenerateOtpResponseKey.Unknown]: 'form.error.default',
};

interface CredentialFieldProps {
    credentialType: AuthCredentialType;
}

const CredentialField: TranslatedComponent<CredentialFieldProps> = ({ trls, credentialType }) => {
    const autofocusRef = useAutofocus();

    const getErrorMessage = (error?: keyof typeof TrlKeys): ReactNode => {
        if (!error) {
            return null;
        }

        if (error === GenerateOtpResponseKey.BadLogin) {
            return trls[TrlKeys[ErrorMessageMap[credentialType]]];
        }

        if (
            credentialType === AuthCredentialType.Phone &&
            !(error === INVALID_PHONE_ERROR || error === MISSING_VALUE_ERROR)
        ) {
            return <PhoneVerificationError error={error} />;
        }

        return trls[TrlKeys[error]];
    };

    const validate: Validator = (value, allValues) => {
        const mustBeValidPattern = UsernameValidatorMap[(allValues as ApplicantLoginFormValues).credentialType];

        return composeValidators(required, mustBeValidPattern)(value);
    };

    return (
        <Field<ApplicantLoginFormValues['username']>
            name={USERNAME_FIELD}
            type="text"
            validate={validate}
            render={({ input, meta }) => {
                const submitError = meta.dirtySinceLastSubmit ? null : (meta.submitError as string);
                const error = (meta.error || submitError) as keyof typeof TrlKeys;
                const errorMessage = getErrorMessage(error);

                const props: ComponentProps<typeof Input> = {
                    ...input,
                    ref: autofocusRef,
                    size: 'large',
                    invalid: meta.touched && !!errorMessage,
                    errorMessage,
                    autoCapitalize: 'off',
                    autoCorrect: 'off',
                    spellCheck: false,
                    onBlur: undefined,
                };

                return (
                    <>
                        {credentialType === AuthCredentialType.Phone && <Input {...props} inputMode="tel" />}
                        {credentialType === AuthCredentialType.Email && <Input {...props} inputMode="email" />}
                    </>
                );
            }}
        />
    );
};

export default translation(CredentialField);
