import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { type TranslatedComponent, translation } from '@hh.ru/front-static-app';
import { VSpacingContainer, Button, Title } from '@hh.ru/magritte-ui';
import { usePush } from '@hh.ru/redux-spa-middleware';

import { useApplicantLoginContext } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/context';
import useAccountTypeSwitch from 'src/components/AccountLogin/hooks/useAccountTypeSwitch';
import { setAccountType } from 'src/models/accountLogin';
import type { AccountType } from 'src/models/accountLogin';
import { UserType } from 'src/models/userType';

import AccountTypeCard from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/MultiStepForm/AccountTypeSelectionStep/AccountTypeCard';

const EMPLOYER_AUTH_PAGE_PATH = '/auth/employer';

const accountTypes: AccountType[] = ['APPLICANT', 'EMPLOYER'];

const TrlKeys = {
    title: 'applicant.login.step.accountTypeSelection.title',
    action: {
        signIn: 'applicant.login.step.accountTypeSelection.action.signIn',
        signUp: 'applicant.login.step.accountTypeSelection.action.signUp',
    },
};

const AccountTypeSelectionStep: TranslatedComponent = ({ trls }) => {
    const [checkedAccountType, setCheckedAccountType] = useState<AccountType>('APPLICANT');
    const { onFlowChange, onNextStep } = useApplicantLoginContext();
    const { onSwitchTo } = useAccountTypeSwitch();
    const dispatch = useDispatch();
    const push = usePush();

    const handleAccountTypeChange = (value: AccountType): void => {
        setCheckedAccountType(value);
        dispatch(setAccountType(value));
    };

    const handleSignInButtonClick = (): void => {
        if (checkedAccountType === 'APPLICANT') {
            onFlowChange('sign-in');
            onNextStep('enter-credentials');
        }

        if (checkedAccountType === 'EMPLOYER') {
            onSwitchTo(UserType.Employer);
        }
    };

    const handleSignUpButtonClick = (): void => {
        if (checkedAccountType === 'APPLICANT') {
            onFlowChange('sign-up');
            onNextStep('enter-credentials');
        }

        if (checkedAccountType === 'EMPLOYER') {
            push(EMPLOYER_AUTH_PAGE_PATH);
        }
    };

    return (
        <VSpacingContainer default={24}>
            <Title Element="h2" size="medium" alignment="center">
                {trls[TrlKeys.title]}
            </Title>
            <VSpacingContainer default={12}>
                {accountTypes.map((accountType) => (
                    <AccountTypeCard
                        key={accountType}
                        accountType={accountType}
                        isChecked={accountType === checkedAccountType}
                        onChange={handleAccountTypeChange}
                    />
                ))}
            </VSpacingContainer>
            <VSpacingContainer default={12}>
                <Button mode="primary" size="large" style="accent" onClick={handleSignInButtonClick}>
                    {trls[TrlKeys.action.signIn]}
                </Button>
                <Button mode="secondary" size="large" style="accent" onClick={handleSignUpButtonClick}>
                    {trls[TrlKeys.action.signUp]}
                </Button>
            </VSpacingContainer>
        </VSpacingContainer>
    );
};

export default translation(AccountTypeSelectionStep);
