import { useState } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import { useCaptcha } from '@hh.ru/hhcaptcha';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';

import {
    LoginByCodeErrorKey,
    LoginByPasswordErrorKey,
    type PostLoginByCodePayload,
    type LoginWithCodeParams,
    type LoginWithCodeReturn,
    type LoginWithPasswordParams,
    type LoginWithPasswordReturn,
    type PostLoginByPasswordPayload,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useLogin/types';
import {
    postLoginByCode,
    postLoginByPassword,
    OperationTypeMap,
} from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/useLogin/utils';

interface UseLogin {
    isLoading: boolean;
    loginWithCode: (params: LoginWithCodeParams) => Promise<LoginWithCodeReturn>;
    loginWithPassword: (params: LoginWithPasswordParams) => Promise<LoginWithPasswordReturn>;
}

const useLogin = (): UseLogin => {
    const authParams = useSelector((state) => state.authUrl);

    const [isLoading, setIsLoading] = useState(false);

    const captcha = useCaptcha();
    const { addNotification } = useNotification();

    const loginWithCode: UseLogin['loginWithCode'] = async ({
        login,
        code,
        accountType,
        backUrl = '/',
        isApplicantSignup = false,
    }) => {
        setIsLoading(true);

        try {
            const payload: PostLoginByCodePayload = {
                username: login,
                code,
                accountType,
                operationType: OperationTypeMap[accountType],
                backurl: backUrl,
                isApplicantSignup,
                remember: true,
            };

            const result = await postLoginByCode(payload);
            setIsLoading(false);

            if (result.success) {
                return { success: true, data: result.data };
            }

            return { success: false, error: result.error };
        } catch (error) {
            setIsLoading(false);
            defaultRequestErrorHandler(error, addNotification);

            return { success: false, error: { key: LoginByCodeErrorKey.Default } };
        }
    };

    const loginWithPassword: UseLogin['loginWithPassword'] = async ({ login, password, failUrl }) => {
        setIsLoading(true);

        try {
            const payload: PostLoginByPasswordPayload = {
                username: login,
                password,
                failUrl: failUrl || authParams?.failurl,
                remember: true,
            };
            captcha.addCaptchaParams(payload);

            const result = await postLoginByPassword(payload);
            setIsLoading(false);

            if (result.success) {
                captcha.updateCaptcha(result.data);

                return { success: true, data: result.data };
            }

            return { success: false, error: result.error };
        } catch (error) {
            setIsLoading(false);
            defaultRequestErrorHandler(error, addNotification);

            return { success: false, error: LoginByPasswordErrorKey.Unknown };
        }
    };

    return { isLoading, loginWithCode, loginWithPassword };
};

export default useLogin;
