import { type TranslatedComponent, translation, useSelector } from '@hh.ru/front-static-app';
import { VSpacingContainer, Title, BrandedButton } from '@hh.ru/magritte-ui';

import Postpone from 'src/components/SignInSignUpFlow/Postpone';
import { SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE } from 'src/components/SignInSignUpFlow/types';

const TrlKeys = {
    title: 'applicant.login.step.socialLogin.title',
};

const SocialLoginStep: TranslatedComponent = ({ trls }) => {
    const { socialNetworks } = useSelector(({ loginForm }) => loginForm);

    const availableSocialNetworks = socialNetworks
        ? socialNetworks.filter(({ code }) => !!SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE[code])
        : [];

    return (
        <VSpacingContainer default={24}>
            <Title Element="h2" size="medium" alignment="center">
                {trls[TrlKeys.title]}
            </Title>
            <VSpacingContainer default={12}>
                {availableSocialNetworks.map(({ code, trl, url }) => (
                    <BrandedButton
                        key={code}
                        Element={Postpone}
                        to={url}
                        rel="nofollow"
                        service={SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE[code]}
                        size="large"
                    >
                        {trl}
                    </BrandedButton>
                ))}
            </VSpacingContainer>
        </VSpacingContainer>
    );
};

export default translation(SocialLoginStep);
