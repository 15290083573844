import { FC, PropsWithChildren } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Card } from '@hh.ru/magritte-ui';
import { BubbleRoundFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { WhatsappBrandSize24, TelegramBrandSize24, ViberBrandSize24 } from '@hh.ru/magritte-ui/service';

import { DeepLink } from 'Utils/DeepLink';
import { getMessengerLinks, CHAT, PHONE, MessengersTypes } from 'Utils/DeepLinkHelper';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import { useIsRabotaBy } from 'src/hooks/useSites';
import { MessengerType } from 'src/models/messengers';

import styles from './styles.less';

interface MessengersProps {
    waStartText?: string;
}

const ICONS = {
    [MessengerType.Telegram]: <TelegramBrandSize24 />,
    [MessengerType.Whatsapp]: <WhatsappBrandSize24 />,
    [MessengerType.Viber]: <ViberBrandSize24 />,
};

const Messengers: FC<MessengersProps & PropsWithChildren> = ({ waStartText }) => {
    const contactPhones = useSelector((state) => state.contactPhones);
    const messengers = useSelector((state) => state.messengers);
    const displayType = useSelector((state) => state.displayType);
    const isMagritte = useMagritte();
    const isRabotBy = useIsRabotaBy();

    if (!messengers?.length || !contactPhones?.length) {
        return null;
    }

    if (!isMagritte) {
        return (
            <div className="helpphone__messengers">
                {messengers.map((messenger) => {
                    const messengerType = messenger.messengerType.toLowerCase() as MessengersTypes;
                    const phone = `${messenger.country}${messenger.city}${messenger.number}`;
                    const links = getMessengerLinks({
                        phone,
                        accountName: messenger.accountName || '',
                        displayType,
                        viberType: isRabotBy ? PHONE : CHAT,
                        waStartText,
                    });

                    return (
                        <span key={messenger.messengerType}>
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();

                                    Analytics.sendHHEventButtonClick('helpphone_messenger', {
                                        goal: messenger.messengerType,
                                    });

                                    const deepLink = new DeepLink({
                                        fallbackUrl: links[messengerType].shallow,
                                        androidUrl: links[messengerType].android,
                                        iosUrl: links[messengerType].ios,
                                    });
                                    deepLink.open(links[messengerType].deep);
                                }}
                                data-type={messengerType}
                                /** Возможные классы:
                                 * helpphone-messenger_whatsapp
                                 * helpphone-messenger_viber
                                 * helpphone-messenger_telegram
                                 */
                                className={`helpphone-messenger helpphone-messenger_${messengerType}`}
                            />
                        </span>
                    );
                })}
            </div>
        );
    }

    return (
        <div className={styles.messengers}>
            {messengers.map((messenger) => {
                const messengerType = messenger.messengerType.toLowerCase() as MessengersTypes;
                const phone = `${messenger.country}${messenger.city}${messenger.number}`;
                const links = getMessengerLinks({
                    phone,
                    accountName: messenger.accountName || '',
                    displayType,
                    viberType: isRabotBy ? PHONE : CHAT,
                    waStartText,
                });

                return (
                    <Card
                        onClick={(e) => {
                            e.stopPropagation();

                            Analytics.sendHHEventButtonClick('helpphone_messenger', {
                                goal: messenger.messengerType,
                            });

                            const deepLink = new DeepLink({
                                fallbackUrl: links[messengerType].shallow,
                                androidUrl: links[messengerType].android,
                                iosUrl: links[messengerType].ios,
                            });
                            deepLink.open(links[messengerType].deep);
                        }}
                        actionCard
                        key={messenger.messengerType}
                        data-qa={messengerType}
                        style="neutral"
                        borderRadius={12}
                        stretched
                        aria-label={messenger.messengerType}
                    >
                        <div className={styles.messenger}>
                            {ICONS[messenger.messengerType] || <BubbleRoundFilledSize24 />}
                        </div>
                    </Card>
                );
            })}
        </div>
    );
};

export default Messengers;
