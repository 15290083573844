import type { AxiosResponse } from 'axios';

import type { PostFormData } from 'Modules/prepareFormData';
import type { FetchResult } from 'src/components/AccountLogin/CombinedLoginCards/ExpApplicantLoginCard/hooks/types';
import type { OtpRequest, OtpType, OtpOperationType } from 'src/models/otp';

type OtpRequestBody = OtpRequest['body'];
type GenerateOtpResponse = AxiosResponse<FetcherPostApi['/account/otp_generate']['response']>;
type GenerateOtpData = GenerateOtpResponse['data'];

export enum GenerateOtpResponseKey {
    CodeSendOk = 'CODE_SEND_OK',
    CodeSendBlocked = 'CODE_SEND_BLOCKED',
    BadLogin = 'BAD_LOGIN',
    CountryBlacklisted = 'COUNTRY_BLACKLISTED',
    Unknown = 'UNKNOWN',
}

export interface PostGenerateOtpPayload extends PostFormData, OtpRequestBody {
    otpType: OtpType;
    operationType?: OtpOperationType;
}
export type PostGenerateOtpResult = FetchResult<GenerateOtpData>;

export interface SendOtpCodeParams extends Partial<PostGenerateOtpPayload> {
    login: string;
    otpType: OtpType;
}
export type SendOtpCodeReturn = PostGenerateOtpResult;
